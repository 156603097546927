export enum TicketStatusEnum {
  New = "0",
  Acknowledged = "1",
  Resolved = "2",
  Closed = "3",
  HoldWaitForOwner = "4",
  Reopened = "5",
  Merged = "6",
  Canceled = "7",
  HoldWaitForExternal = "8",
}

export interface TicketStatus {
  id: string;
  departmentId: string;
  name: string;
  systemName?: string;
  isEnabled: boolean;
}

export const allTicketStatusesIds = Object.values(TicketStatusEnum);

export const inWorkStatusesIds = [
  TicketStatusEnum.New,
  TicketStatusEnum.Acknowledged,
  TicketStatusEnum.Reopened,
  TicketStatusEnum.HoldWaitForOwner,
  TicketStatusEnum.HoldWaitForExternal,
];

export const closedStatusesIds = [TicketStatusEnum.Resolved, TicketStatusEnum.Closed, TicketStatusEnum.Canceled];

export const allowedTicketStatusesForSelection = [
  TicketStatusEnum.Acknowledged,
  TicketStatusEnum.Resolved,
  TicketStatusEnum.HoldWaitForOwner,
  TicketStatusEnum.HoldWaitForExternal,
  TicketStatusEnum.Canceled,
];

export const groupedTicketStatusOptions = [
  {
    name: "In lucru",
    value: inWorkStatusesIds.sort(),
  },
  {
    name: "Inchise",
    value: closedStatusesIds.sort(),
  },
];
