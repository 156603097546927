import { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";

import {
  defaultPageSize,
  defaultPaginationOptions,
  emptyPaginationDetails,
  getPaginationFirst,
  getPaginationOptionsFromDataTable,
} from "../../common/api/paginationHelper";
import { User } from "../users/User";
import UserRoleLabel from "../userRoles/UserRoleLabel";
import UserRolesDropdown from "../userRoles/UserRoleDropdown";
import UserForm from "./UserForm";
import NoResultsFound from "../../common/components/noResultsFound/NoResultsFound";
import { PaginationDetails } from "../../common/types/Pagination";
import { searchUsers, syncUsers, updateUser } from "../users/usersApi";

export default function UsersPage() {
  const [users, setUsers] = useState<User[]>([]);
  const [pagination, setPagination] = useState<PaginationDetails>(emptyPaginationDetails);
  const [first, setFirst] = useState(getPaginationFirst(pagination.pageNumber, defaultPageSize));
  const [rows, setRows] = useState(defaultPageSize);
  const [filters, setFilters] = useState<any>();
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [showUserDialog, setShowUserDialog] = useState(false);

  useEffect(() => {
    doSearchUsers();
  }, [first, rows, filters]);

  const doSearchUsers = async () => {
    const response = await searchUsers({
      firstName: filters?.firstName?.value,
      lastName: filters?.lastName?.value,
      emailAddress: filters?.emailAddress?.value,
      role: filters?.role?.value,
      ...getPaginationOptionsFromDataTable(first, rows),
    });
    setPagination(response.pagination);
    setUsers(response.users);
  };

  const closeDialog = () => {
    setSelectedUser(undefined);
    setShowUserDialog(false);
  };

  const onEdit = (user: User) => {
    setSelectedUser(user);
    setShowUserDialog(true);
  };

  const onSubmit = async (user: User) => {
    await updateUser(user);
    await doSearchUsers();
    closeDialog();
  };

  const onSync = async () => {
    await syncUsers();
    doSearchUsers();
  };

  return (
    <Card
      title={
        <div className="flex align-items-center justify-content-between">
          <div className="text-2xl font-bold">Utilizatori</div>
          <Button label="Sincronizeaza" icon="pi pi-sync" onClick={() => onSync()} />
        </div>
      }
    >
      <DataTable
        size="small"
        lazy
        value={users}
        paginator
        first={first}
        rows={rows}
        rowsPerPageOptions={defaultPaginationOptions}
        totalRecords={pagination.totalCount}
        filterDisplay="row"
        filters={filters}
        filterDelay={500}
        onPage={(e) => {
          setFirst(e.first);
          setRows(e.rows);
        }}
        onFilter={(e) => setFilters(e.filters)}
        emptyMessage={<NoResultsFound />}
      >
        <Column header="Nume" field="lastName" filter filterPlaceholder="Nume" showFilterMenu={false}></Column>
        <Column header="Prenume" field="firstName" filter filterPlaceholder="Prenume" showFilterMenu={false}></Column>
        <Column header="Email" field="emailAddress" filter filterPlaceholder="Email" showFilterMenu={false}></Column>
        <Column
          className="w-14rem"
          header="Rol"
          field="role"
          body={(rowData: User) => <UserRoleLabel value={rowData.role} />}
          filter
          filterElement={(options: ColumnFilterElementTemplateOptions) => (
            <UserRolesDropdown showClear value={options.value} onChange={(e) => options.filterApplyCallback(e)} />
          )}
          showFilterMenu={false}
        ></Column>
        <Column
          className="w-11rem text-right"
          body={(rowData: User) => <Button size="small" label="Editeaza" icon="pi pi-pencil" onClick={() => onEdit(rowData)} />}
        ></Column>
      </DataTable>
      <Dialog className="w-full sm:w-6" header="Editeaza utilizator" visible={showUserDialog} onHide={() => closeDialog()}>
        <UserForm user={selectedUser} onSubmit={onSubmit} />
      </Dialog>
    </Card>
  );
}
